<template>
    <!-- 首页轮播 -->
    <div class="carousel-container" @mouseover="pause" @mouseout="play">
        <div :class="['carousel-list',{tran:isTran}]" :style="listPosition">
            <div v-for="item,index in newCarouselList" :key='index'>
                <!-- <div>客户至上，快来工作</div> -->
                <img style="width: 100%" :src="item.titleImg" alt="">
                <!-- <div>
                    <div>
                        <img :src="item.titleImg" alt="">
                        <h2>{{item.title}}</h2>
                    </div>
                    <h3>{{item.intro}}</h3>
                    <h4></h4>
                    <p>{{item.introDetail}}</p>
                    <ul>
                        <li v-for='itm,idx in item.lightspot' :key='idx'>
                            <h5>{{itm.title}}</h5>
                            <p>{{itm.rlt}}</p>
                        </li>
                    </ul>
                    <img :src="item.showImg" alt="#">
                </div> -->
            </div>
        </div>
        <ul class="carousel-dot">
            <li 
            v-for="item,index in carouselList" 
            :key='index'
            :class="{active:index===dotIndex}"
            @click="handleActive(index)"
            ></li>
        </ul>

        <span class="prev" @click="handlePrev"><img src="../assets/imgs/icon_slide_left.png" alt=""></span>
        <span class="next" @click="handleNext"><img src="../assets/imgs/icon_slide_right.png" alt=""></span>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                carouselList:[
                    {
                        titleImg:require('../assets/imgs/img_bg01.png'),
                    },
                    {
                        titleImg:require('../assets/imgs/img_bg02.png'),
                    },
                    {
                        titleImg:require('../assets/imgs/img_bg01.png'),
                    },
                ],
                activeIndex:0,//列表下标切换
                dotIndex:0,//小圆点下标切换
                isTran:true,//类名控制是否添加过渡效果
                helpNum:111,//轮播简单节流
                timer:null,
            }
        },
        computed: {
            listPosition() {
                return {
                    width:1920*(this.carouselList.length+2)+'px',
                    transform:'translateX(-'+ 1920*(this.activeIndex+1) + 'px'
                } 
            },
            newCarouselList(){
                let arr = [...this.carouselList]
                let firstImg = arr[0]
                let lastImg = arr[arr.length-1]
                arr.push(firstImg)
                arr.unshift(lastImg)
                return arr
            },
        },
        methods: {
            handleNext() {
                if(this.helpNum === 111){
                    this.activeIndex++
                    this.dotIndex =  this.dotIndex == this.carouselList.length - 1 ? 0 : this.dotIndex + 1
                    if(this.activeIndex==this.newCarouselList.length-2&&this.helpNum===111){
                    this.helpNum = 222
                    setTimeout(()=>{
                        this.isTran = false
                        this.activeIndex = 0
                        this.helpNum = 111
                    },500)
                    }
                    this.isTran = true
                }
            },
            handlePrev(){
                if(this.helpNum === 111){
                    this.activeIndex--
                    this.dotIndex =  this.dotIndex == 0 ? this.carouselList.length - 1 : this.dotIndex - 1
                    if(this.activeIndex==-1){
                    this.helpNum = 222
                    setTimeout(()=>{
                        this.isTran = false
                        this.activeIndex = this.newCarouselList.length-3
                        this.helpNum = 111
                    },500)
                    }
                    this.isTran = true
                }
            },
            handleActive(i){
                this.isTran = true
                this.dotIndex = i
                this.activeIndex = i
            },
            pause(){
                clearInterval(this.timer)
            },
            play(){
                this.autoSwitch()
            },
            autoSwitch(){
                this.timer = setInterval(()=>{
                    this.handleNext()
                },3600)
            },
        },
        created(){
            this.autoSwitch()
        },
        destroyed(){
            clearInterval(this.timer)
        }
    }
</script>

<style lang="less" scoped>
    @import url('../assets/css/theme-color.less');
    .carousel-container{
        // width: 100%;
        width: 1856px;
        // height: 100%;
        // height: 560px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        .carousel-list{
            width: 100%;
        //   width: 1920px;
          display: flex;
          height: 100%;
          &.tran{
            transition: .5s;
          }
          >div{
            width: 100%;
            >div{
                background-image: url(../assets/imgs/img_bg01.png);
                height: 450px;
                position: relative;
                padding: 40px;
                border-radius: 12px;
                background: #fefefe;
                >div{
                    display: flex;
                    align-items: center;
                    margin-bottom: 45px;
                    >img{
                        width: 68px;
                        margin-right: 12px;
                    }
                    h2{
                        font-size: 28px;
                        font-weight: 600;
                    }
                }
                >h3{
                    font-size: 20px;
                    font-weight: 600;
                }
                >h4{
                    width: 30px;
                    height: 2px;
                    background: #343434;
                    margin: 16px 0 24px;
                }
                >p{
                    width: 480px;
                    font-size: 14px;
                    color: #77787c;
                    margin-bottom: 60px;
                }
                >ul{
                    display: flex;
                    li{
                        text-align: center;
                        padding-right:38px;
                        h5{
                            font-size: 14px;
                            color: #adadad;
                        }
                        p{
                            color: @themeColor;
                            font-size: 36px;
                            font-weight: 600;
                        }
                        &:not(:first-child){
                            border-left: 1px solid #dedede;
                            padding-left: 38px;
                        }
                    }
                }
                >img{
                    position: absolute;
                    width: 490px;
                    height: 490px;
                    top: -60px;
                    right: 16px;
                }
            }
          }
        }
        .carousel-dot{
          position: absolute;
          bottom: 6px;
          left: 855px;
          display: flex;
          li{
            width: 28px;
            height: 6px;
            border-radius: 3px;
            margin: 5px;
            background: #e2e3e4;
            cursor: pointer;
            transition: .5s;
            &.active{
              width: 50px;
              background: @themeColor;
            }
          }
        }
        span{
          position: absolute;
          top: 50%;
          transform:translateY(-50%);
          width: 60px;
          height: 60px;
          text-align: center;
          line-height: 60px;
          &::selection{
            background:transparent;
          }
          cursor: pointer;
        }
        .prev{
            position: absolute;
            left: 470px;
        }
        .next{
            position: absolute;
            right: 470px;
        }
   
      }
</style>