<template>
  <div id="app">
    <Header/>
      <keep-alive>
        <router-view/>
      </keep-alive>
    <Footer/>
    <div @click="handleBackTop" class="back-top" v-show="showBackTop">
      <img src="./assets/img/top.png" alt="top">
    </div>
  </div>
</template>
<script>
    import Header from './components/Header.vue' 
    import Footer from './components/Footer.vue'
    export default {
        components:{
          Header,Footer
        },
        data() {
          return {
            timer: null,
            showBackTop:false
          }
        },
        methods: {
          handleScroll(){
            let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
            this.timer && clearTimeout(this.timer)
            this.timer = setTimeout(()=>{
              this.showBackTop = scrollTop >= 768 ? true : false
            },50)
          },
          handleBackTop(){
            document.getElementsByTagName('html')[0].scrollTop = 0
          }
        },
        mounted(){
          window.addEventListener('scroll',this.handleScroll,true)
        },
        destroyed(){
          window.removeEventListener('scroll',this.handleScroll)
        }
    }
</script>
<style lang="less">
  @import url('./assets/css/theme-color.less');
  #app{
    font-family: PingFangSC-Regular, PingFang SC;
    width: 100%;
    min-width: 1856px;
    background-color: #F9FAFF;
    .back-top{
      position: fixed;
      right: 98px;
      bottom: 98px;
      width: 46px;
      height: 46px;
      padding: 8px;
      background: white;
      cursor: pointer;
      border: 1px solid #83d1f0;
      border-radius: 23px;
      transition: .2s;
      display: flex;
      align-items: center;
      &:hover{
        background: #ddf0fb;
      }
      img{
        width: 100%;
      }
    }
  }
</style>
