<template>
    <!-- 头部 -->
    <div class="header" :style="{'left': left + 'px'}">
        <!-- <div class="virtualHeight"></div> -->
        <div :class="['headerContent']">
                <div>
                    <img src="../assets/imgs/img_logo.png" alt="">
                </div>
                <ul>
                    <li 
                        v-for="item,index in routerList" 
                        :key='index'
                        :class="{active:activeIndex==index}"
                        @click="handleChangePage(index)"
                        >
                            <i>{{item.title}}</i>
                            <span></span>
                    </li>
                    <li>中</li>
                    <li style="color: #999999;">EN</li>
                </ul>
        </div>
    </div>
</template>

<script>
    import {Bus} from '../utils/bus.js'
    export default {
        data() {
            return {
                activeIndex: 0,
                isFixed:true,
                left: 0,
                routerList:[
                    {
                        path:'/',
                        title:'首页'
                    },{
                        path:'/adstyle',
                        title:'合作企业'
                    },{
                        // path:'/sdk',
                        title:'加入我们'
                    },{
                        // path:'/service',
                        title:'联系我们'
                    },
                ]
            }
        },
        mounted() {
            // console.log(this.show);
            window.addEventListener('scroll', this.handleScroll, true)
        },
        methods: {
            handleChangePage(i) {
                this.activeIndex = i
                this.isFixed = i === 1 ? false : true
                this.$router.history.current.path !== this.routerList[i].path
                &&
                this.$router.push(this.routerList[i].path)
                this.routerList[i].path === '/helpcenter' && Bus.$emit('changeComponentShow') 
            },
            handleScroll() {
                this.left = -window.pageXOffset
                console.log(this.left);
            }
        },
        // 
        watch: {
            $route(to,from){
                // console.log(to.path)
                this.isFixed = to.path === '/adstyle' ? false : true
                if(to.path === '/' || to.path ==='/adstyle' || to.path ==='/sdk' || to.path ==='/dynamic'){
                    Bus.$emit('changShowJoin',true)
                    this.show = 1
                }else{
                    Bus.$emit('changShowJoin',false)
                    this.show = 0
                }
                if(to.path === '/helpcenter'){
                    Bus.$emit('changeComponentShow')
                }
                document.getElementsByTagName('html')[0].scrollTop = 0
                let {routerList} = this
                for(let i in routerList){//解决刷新路由标题上方横横杠不显示
                    // console.log(to.path, routerList[i].path);
                    if(to.path === routerList[i].path){
                        this.activeIndex = i
                        return
                    }
                }
                this.activeIndex = 4 //若在帮助中心详情页刷新时，显示帮助中心
            },
        }
    }
</script>

<style lang="less" scoped>
    @import url('../assets/css/theme-color.less');
    .header{
        position: fixed;
        top: 0;
        width: 100%;
        min-width: 1850px;
        z-index: 1000;
        background: white;
        border-bottom: 1px solid #ececec;
        .headerContent{
            height: 80px;
            width: 1000px;
            padding:0 26px;
            display: flex;
            margin: 0 auto;
            align-items: center;
            justify-content: space-between;
            >div:nth-child(1){
                height: 100%;
                display: flex;
                align-items: center;
                img{
                    width: 98px;
                    height: 40px;
                }
                span{
                    display: inline-block;
                    height: 80%;
                    width: .5px;
                    background: #dedede;
                    margin: 0 12px;
                }
            }
            ul{
                display: flex;
                align-items: center;
                li{
                    height:78px;
                    line-height: 78px;
                    margin: 0 18px;
                    padding: 0 2px;
                    position: relative;
                    cursor: pointer;
                    i{
                        width: 100%;
                        height: 100%;
                        color: #666;
                    }
                    span{
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: 0;
                        height: 4px;
                        transition: .3s;
                    }
                    &:nth-child(1){
                        padding: 0 16px;
                    }
                    &.active{
                        span{
                            background: @themeColor;
                            width: 100%;
                            left: 0;
                        }
                    }
                    &:hover{
                        span{
                            background: @themeColor;
                            width: 100%;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
</style>