<template>
  <div class="home">

    <!-- 顶部轮播 -->
    <div class="carousel">
      <Carousel />
    </div>

    <!-- 关于我们 -->
    <div class="regards">
      <h2 class="banner">关于我们</h2>
      <p class="regards_p">8年专注于app流量及推广领域全国同行业</p>
      <div class="regards_img">
        <img src="../assets/imgs/img_line.png" alt="">
      </div>
      <div class="display">
        <div class="details_left">
          <img src="../assets/imgs/img_photo.png" alt="">
          <div>江西帝集网络有限公司</div>
        </div>
        <div class="details_right">
          <p>
            帝集公司简介：全称江西帝集网络有限公司,我们是一家互联网+的公司专属于做流量变现、品牌推广、App拉新。公司流量来源于各大媒体的大 v 资源及全国充电桩、停车场、共享单车、祝福类平台的投放，近几年通过 和各类银行企业 现金贷，证券类，银行类，合作累计了一大批稳定的客户资源， 公司旗下合作的充电桩覆盖人群1000万+ 日活跃70万+，旗下合作停车场用户5000万+，日活跃用户500万+，共享单车用户6000万+日活跃用户80万+。另有流量来源于公司社群推广及各大积分墙平台。通过线上代理，平台，工会整合构建线上服 务网络渠道，为企业提供可以多元化的外包服务,用户推广，并将企业的需求以任务形式快速发布在 其需要落地的区域，帮助企业快速占领市场。目前已经构建下沉资源的众包服务矩阵,覆 盖线上线下全方位,提供从流量推广、设备投放、服务外包等多领域的众包服务。
          </p>
        </div>
      </div>
    </div>

    <!-- 我们能做什么 -->
    <div class="able">
      <h2 class="banner">我们能做什么</h2>
      <div class="able_img">
        <img src="../assets/imgs/img_line.png" alt="">
      </div>
      <div class="display">
        <div class="box">
          <img class="box_img" src="../assets/imgs/img_bg1.png" alt="">
          <div class="box_content">
            <div>纯点击</div>
            <p>日活千万+，可通过H5/页面等形式进行纯点击补量补效</p>
          </div>
        </div>
        <div class="box">
          <img class="box_img" src="../assets/imgs/img_bg2.png" alt="">
          <div class="box_content">
            <div>流量变现</div>
            <p>运营总平台日活跃用户千万+，可通过口令、唤醒、广告复用、阅读、电商商城为有流量的平台提供技术支持和广告变现。可运营的平台类型含H5、小程序、APP</p>
          </div>
        </div>
        <div class="box">
          <img class="box_img" src="../assets/imgs/img_bg3.png" alt="">
          <div class="box_content">
            <div>电商补量</div>
            <p>以京东、淘宝、唯品会等进行纯UV补量补效</p>
          </div>
        </div>
        <div class="box">
          <img class="box_img" src="../assets/imgs/img_bg4.png" alt="">
          <div class="box_content">
            <div>社群量</div>
            <p>专业做各种CPA类产品推广拉新补量补效（加粉 教育回访听课  授权 注册 下载 实名 绑卡 出额 借款 充值 游戏试玩 证券开户 银行揽储大额存单 银行开户）</p>
          </div>
        </div>
        <div class="box">
          <img class="box_img" src="../assets/imgs/img_bg5.png" alt="">
          <div class="box_content" style="position: absolute;top: 65px;width: 100%;">
            <div>品牌roi补量</div>
            <p>承接各大奢侈品品牌、快消品、电器等roi补量，优 惠折扣力度大单天销售额可达千万+</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 产品服务 -->
    <div class="product">
      <h2 class="banner">产品服务</h2>
      <div class="product_img">
        <img src="../assets/imgs/img_line.png" alt="">
      </div>
      <div class="display">
        <div v-for="item, i in service" :key="i" class="product_box">
          <div>{{ item.title }}</div>
          <p>{{ item.content }}</p>
        </div>
      </div>
    </div>

    <!-- 资源优势 -->
    <div class="resource">
      <h2 class="banner">资源优势</h2>
      <div class="resource_img">
        <img src="../assets/imgs/img_line.png" alt="">
      </div>
      <div class="resource_box">
        <div class="resource_box_footer">

          <!-- 地推团队 -->
          <div class="ground over ground_box" @mouseover="mousee(1)">
            <p>地推团队</p>
            <div>
              <img class="img1" src="../assets/imgs/icon_resources_01.png" alt="">
            </div>
          </div>
          <div @mouseover="mousee(1)" :class="{ 'ground_div': true, 'show': over == 1, 'ground_details': true }">
            <img src="../assets/imgs/icon_resources_01.png" alt="">
            <div>地推团队</div>
            <p>承接各大品牌落地服务推广，校园推广</p>
            <p>全国合作地推团队1000+， 人员20000+，可快速执行</p>
          </div>

          <!-- 流量变现 -->
          <div class="flow over ground_box" @mouseover="mousee(2)">
            <p>流量变现</p>
            <div>
              <img class="img2" src="../assets/imgs/icon_resources_02.png" alt="">
            </div>
          </div>
          <div @mouseover="mousee(2)" :class="{ 'flow_div': true, 'show': over == 2, 'ground_details': true }">
            <img src="../assets/imgs/icon_resources_02.png" alt="">
            <div style="margin: 18px 0 33px 0;font-size: 16px;color: #FFFFFF;">流量变现</div>
            <p>公司有完整的变现体系，
              一年的广告收益可达1亿+</p>
            <p>可为现有变现平台附加额 外25%增值收益</p>
          </div>

          <!-- 口罩机 -->
          <div class="mask over ground_box" @mouseover="mousee(3)">
            <p>口罩机</p>
            <div>
              <img class="img3" src="../assets/imgs/icon_resources_03.png" alt="">
            </div>
          </div>
          <div @mouseover="mousee(3)" :class="{ 'mask_div': true, 'show': over == 3, 'ground_details': true }">
            <img src="../assets/imgs/icon_resources_03.png" alt="">
            <div>口罩机</div>
            <p>直接流量方，100%真实
              自然用户。日产10w+自 然用户粉</p>
            <p>提供为贵方公众号涨粉、 企业微信增粉、视频号增
              粉、个人微信、支付宝生 活号增粉</p>
          </div>

          <!-- 线上推广 -->
          <div class="spread over ground_box" @mouseover="mousee(4)">
            <p>线上推广</p>
            <div>
              <img class="img4" src="../assets/imgs/icon_resources_04.png" alt="">
            </div>
          </div>
          <div @mouseover="mousee(4)" :class="{ 'spread_div': true, 'show': over == 4, 'ground_details': true }">
            <img src="../assets/imgs/icon_resources_04.png" alt="">
            <div>线上推广</div>
            <p>
              全国校园500+所合作院校 资源，几个百万级流量公 会，拥有百万人数社群， 裂变 社群 校园 积分墙</p>
            <p>承接cpa，承接理财平台cps， 专业大媒体资源补量 ， 各
              种app下载注册，银行绑卡 入金项目，影视三大平台 （淘票票，猫眼，豆瓣）相 关业务打包</p>
          </div>
        </div>
      </div>



    </div>

    <!-- 更多企业选择帝集 -->
    <div class="teamwork">
      <h2 class="banner">更多企业选择帝集</h2>
      <div class="teamwork_img">
        <img src="../assets/imgs/img_line.png" alt="">
      </div>
      <div class="teamwork_box">
        <img class="top_img" src="../assets/imgs/img_firm_01.png" alt="">
        <img class="top_img" src="../assets/imgs/img_firm_02.png" alt="">
        <img class="top_img" src="../assets/imgs/img_firm_03.png" alt="">
        <img class="top_img" src="../assets/imgs/img_firm_04.png" alt="">
        <img class="top_img" src="../assets/imgs/img_firm_05.png" alt="">
        <img class="top_img" src="../assets/imgs/img_firm_06.png" alt="">
        <img src="../assets/imgs/img_firm_07.png" alt="">
        <img class="down_img" src="../assets/imgs/img_firm_08.png" alt="">
        <img class="down_img" src="../assets/imgs/img_firm_09.png" alt="">
        <img class="down_img" src="../assets/imgs/img_firm_10.png" alt="">
        <img class="down_img" src="../assets/imgs/img_firm_11.png" alt="">
        <img class="down_img" src="../assets/imgs/img_firm_12.png" alt="">
        <img class="down_img" src="../assets/imgs/img_firm_13.png" alt="">
        <img src="../assets/imgs/img_firm_14.png" style="margin-top: 10px;" alt="">
      </div>
    </div>

  </div>
</template>

<script>
import HomeAdStyle from '../components/HomeAdType.vue'
import Carousel from '../components/Carousel.vue'
export default {
  components: {
    HomeAdStyle, Carousel
  },
  data() {
    return {
      over: 4,
      permissible: [
        {
          title: '纯点击',
          content: '日活千万+，可通过H5/页面等形式进行纯点击补量补效',
          img: '../assets/imgs/img_bg1.png'
        },
        {
          title: '流量变现',
          content: '日活千万+，可通过H5/页面等形式进行纯点击补量 补效纯点击 以京东、淘宝、唯品会等进行纯UV补量补效 电商补量 为有流量的平台提供变现，H5和小程序平台均可',
          img: '../assets/imgs/img_bg2.png'
        },
        {
          title: '电商补量',
          content: '以京东、淘宝、唯品会等进行纯UV补量补效',
          img: '../assets/imgs/img_bg3.png'
        },
        {
          title: '口罩机',
          content: '提供为贵方公众号涨粉、企业微信增粉、视频号增 粉、个人微信、支付宝生活号增粉直接流量方， 100%真实自然用户、日产10w+自然用户粉',
          img: '../assets/imgs/img_bg4.png'
        },
        {
          title: '品牌roi补量',
          content: '承接各大奢侈品品牌、快消品、电器等roi补量，优 惠折扣力度大单天销售额可达千万+',
          img: '../assets/imgs/img_bg5.png'
        },
      ],
      service: [
        {
          title: '流量企业广告变现',
          content: '公司目前运营流量6000万+日活跃用户200万+，我们有多方面独特的变现途径从而产生高收益的广告费用。可以承接H5、小程序、APP等平台流量变现'
        },
        {
          title: '流量平台增值服务',
          content: '在提供基本服务的基础上，满足更多的顾客期望，为客户提 供更多的利益和不同于其他企业的优质服务，可以在现有的广告收益基础上额外增加25%左 右的收益。'
        },
        {
          title: '众包推广、APP拉新',
          content: '用户拉新、高校品牌营销、地推、推广外包城市落地等服务。'
        },
      ],
    }
  },
  methods: {
    mousee(i) {
      this.over = i
    }
  },
}
</script>
<style lang="less" scoped>
@import url('../assets/css/theme-color.less');
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
.home {
  margin-top: 80px;

  .banner {
    text-align: center;
    font-size: 32px;
    color: #4E7CFF;
    font-weight: 500px;
  }

  .regards {
    width: 1000px;
    margin: 0 auto;
    margin-top: 32px;

    .regards_p {
      margin-top: 3px;
      font-size: 14px;
      color: #666666;
      text-align: center;
    }

    .regards_img {
      margin-top: 10px;
      text-align: center;
    }

    .display {
      display: flex;

      .details_left {
        width: 395px;
        margin-right: 34px;
        position: relative;

        div {
          position: absolute;
          bottom: 20px;
          left: 118px;
        }
      }

      .details_right {
        width: 570px;

        p {
          text-indent: 32px;
          font-size: 16px;
          color: #333333;
          text-align: justify;
          line-height: 30px;
        }
      }
    }
  }

  .able {
    width: 1000px;
    margin: 0 auto;
    margin-top: 80px;

    .able_img {
      text-align: center;
      margin-top: 13px;
    }

    .display {
      display: flex;

      .box {
        margin-right: 20px;
        width: 182px;
        height: 324px;
        position: relative;

        .box_img {
          width: 182px;
          height: 324px;
        }

        .box_content {
          position: absolute;
          top: 65px;
          width: 100%;

          div {
            margin: 32px 0 9px 0;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            color: #666666;
          }

          p {
            width: 124px;
            margin: 0 auto;
            font-size: 12px;
            color: #666666;
            text-align: justify;
          }
        }
      }
    }
  }

  .product {
    width: 1000px;
    margin: 0 auto;
    margin-top: 80px;

    .product_img {
      text-align: center;
      margin-top: 13px;
    }

    .display {
      display: flex;

      .product_box {
        border-left: 4px solid #3F7BFE;
        border-radius: 3px;
        padding-left: 12px;
        margin-top: 32px;
        margin-right: 50px;

        div {
          font-size: 24px;
          color: #333333;
          font-weight: 500;
        }

        p {
          width: 274px;
          font-size: 14px;
          color: #666666;
          line-height: 30px;
          margin-top: 13px;
          text-align: justify;
        }
      }
    }
  }

  .resource {
    width: 1000px;
    margin: 0 auto;
    margin-top: 80px;
    position: relative;

    .resource_img {
      text-align: center;
      margin-top: 13px;
    }

    .resource_box {
      background-image: url(../assets/imgs/img_bg_resource.png);
      height: 370px;
      background-color: #F3F3F3;
      position: relative;
      margin-top: 62px;

      .resource_box_footer {
        height: 120px;
        display: flex;
        color: #fff;
        position: absolute;
        bottom: 0;
        width: 100%;

        .ground_box {
          width: 25%;

          p {
            margin-left: 60px;
            margin-top: 5px;
          }

          div {
            margin-left: 176px;

            .img1 {
              width: 45px;
              height: 44px;
            }

            .img2 {
              width: 43px;
              height: 44px;
            }

            .img3 {
              width: 39px;
              height: 49px;
            }

            .img4 {
              width: 43px;
              height: 42px;
            }
          }
        }

        .ground_div {
          left: 35px;
        }

        .flow_div {
          left: 275px;
        }

        .mask_div {
          left: 515px;
        }

        .spread_div {
          left: 760px;
        }

        .ground_details {
          width: 210px;
          height: 400px;
          background-color: #4E7CFF;
          padding: 59px 29px 42px 29px;
          position: absolute;
          bottom: 0px;
          border-radius: 10px 10px 0 0;

          div {
            margin: 18px 0 33px 0;
            font-size: 16px;
            color: #FFFFFF;
          }

          p {
            font-size: 12px;
            color: #FFFFFF;
            width: 152px;
            margin-bottom: 14px;
            text-align: justify;
          }
        }
      }
    }
  }

  .teamwork {
    width: 1000px;
    margin: 0 auto;
    margin-top: 80px;

    .teamwork_img {
      text-align: center;
      margin-top: 13px;
    }


    .teamwork_box {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;

      img {
        width: 120px;
        height: 60px;
      }

      .top_img {
        margin-right: 26px;
      }

      .down_img {
        margin-right: 26px;
        margin-top: 10px;
      }
    }
  }
}




.ground_div,
.flow_div,
.mask_div,
.spread_div {
  opacity: 1;
  // transition: opacity .5s
}

.show {
  opacity: 1;
}

.over {
  padding: 25px 0 24px 0;
  background-color: #4E7CFF;
  opacity: .25;
}

// .ground:hover~.ground_div,
// .flow:hover~.flow_div,
// .mask:hover~.mask_div,
// .spread:hover~.spread_div {
//   opacity: 1;
// }
</style>
